
import * as aroraProviderRuntime$srD2XFQLiexYD_DzLU8YQoBYsiMm5o83XLzkUzFtAB0 from 'D:/deploy/UploadHidden/20250410-P903.342/arora-front/satellite/build-rest-2dec7b3d-b12a-4e27-8c61-53607d26f817/providers/aroraProvider.ts'

export const imageOptions = {
  "screens": {
    "xs": 320,
    "sm": 640,
    "md": 768,
    "lg": 1024,
    "xl": 1280,
    "xxl": 1536,
    "2xl": 1536
  },
  "presets": {},
  "provider": "aroraProvider",
  "domains": [],
  "alias": {},
  "densities": [
    1,
    2
  ],
  "format": [
    "webp"
  ]
}

imageOptions.providers = {
  ['aroraProvider']: { provider: aroraProviderRuntime$srD2XFQLiexYD_DzLU8YQoBYsiMm5o83XLzkUzFtAB0, defaults: {} }
}
        